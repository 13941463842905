// Modal popup
html.modal-visible {
	body {
		overflow: hidden;
	}
	.modal-container {
		opacity: 1;
		pointer-events: auto;
		overflow: hidden;
		@include md {
			display: block;
			overflow-y: scroll;
			padding: 4rem 0;
		}
	}
}
.instagram-img-container {
	max-width: 100%; /*any size*/
	height: auto; /*any size*/
}
.object-fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover; /*magic*/
}
.min-dimensions {
	min-width: 200px;
	min-height: 200px;
}
.modal-background {
	will-change: transform;
	transform: translateZ(0); /*for older browsers*/
}
// @supports (backdrop-filter: none) {
// 	.modal-background {
// 		backdrop-filter: blur(5px);
// 	}
// }
.modal-container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
}
.close-button-container {
	position: absolute;
	margin: 0;
	padding: 0;
	top: 0;
	right: 0;
	overflow: hidden;
	z-index: 10;
	button.close-button {
		background: none;
		margin: 0;
		padding: 0;
		width: 4rem;
		height: 4rem;
		border: none;
		cursor: pointer;
		pointer-events: auto;
		outline: 0;
		border: 0;
		outline: none;
		&:hover {
			svg {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}
}
.buttons {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 0;
	max-height: 0;
	margin: auto;
	pointer-events: auto;
	user-select: none;
	button.prev-button,
	button.next-button {
		position: absolute;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}
}
.button-container {
	.buttons {
		button.prev-button {
			width: 8rem;
			height: 12rem;
			margin-left: -2rem !important;
			left: -50vw;
			&:hover {
				svg {
					background-color: rgba(255, 255, 255, 0.1);
				}
			}
			@include md {
				bottom: -50vh;
			}
			&.hide {
				opacity: 0;
				z-index: -1;
				pointer-events: none;
			}
		}
		button.next-button {
			width: 8rem;
			height: 12rem;
			margin-right: -2rem !important;
			right: -50vw;
			&:hover {
				svg {
					background-color: rgba(255, 255, 255, 0.1);
				}
			}
			@include md {
				bottom: -50vh;
			}
			&.hide {
				opacity: 0;
				z-index: -1;
				pointer-events: none;
			}
		}
	}
}
.carousel-container {
	.buttons {
		button.prev-button,
		button.next-button {
			width: 8rem;
			height: 12rem;
			&:hover {
				svg {
					background-color: #22292f;
				}
			}
		}
		button.prev-button {
			margin-left: -2rem !important;
		}
		button.next-button {
			margin-right: -2rem !important;
		}
	}
}
// transition: all 0.2s ease;
.modal,
.instagram-page {
	.media-container {
		user-select: none;
		.carousel-container {
			transition: opacity 0.2s ease;
			&:hover {
				.slide-counter-display {
					opacity: 1;
				}
				.slide-dots-indicator {
					.fade {
						opacity: 1;
					}
				}
			}
			&.playing {
				.slide-counter-display,
				.slide-dots-indicator {
					opacity: 0;
				}
				.buttons {
					button.prev-button,
					button.next-button {
						opacity: 0;
					}
				}
			}
			.slide-counter-display {
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
					Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
			}
			.slide-dots-indicator {
				position: absolute;
				width: 100%;
				bottom: 1rem;
				font-size: 6px;
				line-height: 6px;
				text-align: center;
				pointer-events: none;
				.dots {
					display: inline-block;
					position: relative;
					z-index: 2;
					&:before {
						content: "\f111";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						text-align: center;
						margin: 0 0.1rem;
						color: rgba($white, 0.4);
					}
					&.active {
						&::before {
							color: $white;
						}
					}
				}
				.fade {
					position: absolute;
					left: 0;
					bottom: 0;
					margin-bottom: -1rem;
					width: 100%;
					height: 65px;
					opacity: 0;
					background: linear-gradient(
						to bottom,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 0.5) 100%
					);
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					pointer-events: none;
					will-change: opacity;
					z-index: 1;
					transition: opacity 0.2s ease;
				}
			}
			.buttons {
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				max-width: 100%;
				margin: auto;
				user-select: none;
				box-shadow: -6px 0 80px 6px rgba(0, 0, 0, 0.8);
				&.show {
					opacity: 1;
				}
				&:hover {
					opacity: 1;
				}
				.prev-button,
				.next-button {
					position: absolute;
					margin: 0;
					padding: 0;
					cursor: pointer;
					pointer-events: auto;
				}
				.prev-button {
					left: 0;
					&.hide {
						opacity: 0;
						z-index: -1;
						pointer-events: none;
					}
				}
				.next-button {
					right: 0;
					&.hide {
						opacity: 0;
						z-index: -1;
						pointer-events: none;
					}
				}
			}
			&:hover {
				.buttons {
					opacity: 1;
				}
			}
		}
		.video-container,
		.carousel-container,
		.media-container {
			.video-image-container,
			.carousel-media-container,
			.image-container {
				@include md {
					width: 100% !important;
					height: 100% !important;
				}
				img,
				video {
					width: 100%;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					margin: 0;
					padding: 0;
				}
				img {
					z-index: 1;
				}
			}
			.mute-button {
				background-image: $muteButtonIcon;
				background-size: 100% 100%;
				background-position: center;
				pointer-events: auto;
				user-select: none;
			}
			.unmute-button {
				background-image: $unmuteButtonIcon;
				background-size: 100% 100%;
				background-position: center;
				pointer-events: auto;
				user-select: none;
			}
			.video-icon {
				background-image: $videoIcon;
				background-size: 100% 100%;
				background-position: center;
			}
			.video-icon,
			.mute-button,
			.unmute-button {
				position: absolute;
				width: 22px;
				height: 22px;
				bottom: 0.75rem;
				right: 0.75rem;
				opacity: 0.8;
				pointer-events: auto;
				user-select: none;
				&:hover {
					opacity: 1;
				}
			}
			.play-button {
				background-image: $playButtonIcon;
				background-repeat: no-repeat;
				background-position: center;
				width: 135px;
				height: 60px;
				opacity: 1;
				pointer-events: auto;
				user-select: none;
				transition: opacity 0.2s ease;
				&.hide {
					opacity: 0;
					z-index: -1;
				}
			}
			&:hover {
				.play-button {
					opacity: 1;
				}
			}
		}
		img,
		video {
			width: 100%;
		}
	}
	.content {
		margin-right: 335px;
		@include md {
			margin-right: 0;
		}
	}
	.sidebar {
		width: 335px;
		@include md {
			width: 100%;
		}
		a {
			&.button {
				position: absolute;
				bottom: 0;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}

.carousel-item {
	-webkit-transition-duration: 0.1s, 0.3s;
	transition-duration: 0.1s, 0.3s;
	-webkit-transition-property: opacity, width;
	transition-property: opacity, width;
	-webkit-transition-timing-function: linear, ease;
	transition-timing-function: linear, ease;
}

.loading-skeleton {
	display: inline-block;
	position: relative;
	overflow: hidden;
	background-color: transparent;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#e8e8e8, 0) 0,
			rgba(#e8e8e8, 0.2) 20%,
			rgba(#e8e8e8, 0.5) 50%,
			rgba(#e8e8e8, 0)
		);
		animation: shimmer 1.2s infinite;
		content: "";
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}

.tv-static {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
