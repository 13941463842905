// Instagram icons
$carouselIcon: url(../../assets/images/buttons/carousel-icon.svg);
$videoIcon: url(../../assets/images/buttons/video-icon.svg);
$playButtonIcon: url(../../assets/images/buttons/play-button-icon.svg);
$muteButtonIcon: url(../../assets/images/buttons/mute-button-icon.svg);
$unmuteButtonIcon: url(../../assets/images/buttons/unmute-button-icon.svg);
$downArrow: url(../../assets/images/buttons/down-arrow.svg);

// Background images
$bgFonze: url(../../assets/images/themes/bg-fonze.jpg);
$bgFonzeDarkMode: url(../../assets/images/themes/bg-fonze_dark-mode.jpg);
$darkMode: url(../../assets/images/themes/wood.jpg);
$bgBedroom: url(../../assets/images/themes/bg-bedroom.jpg);
$bgBedroomDarkMode: url(../../assets/images/themes/bg-bedroom_dark-mode.jpg);
$error: url(../../assets/images/themes/static.gif);
$blog: url(../../assets/images/themes/blog.jpg);
$home: url(../../assets/images/themes/home.jpg);

// Colors
$white: #ffffff;
