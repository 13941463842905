.instagram-feed {
	.loading-container {
		margin: 0;
	}
	.post {
		transition: all 0.4s;
		transform: translate3d(0, 0, 0);
		&:hover {
			transform: translateY(-0.25rem);
		}
		.post-content {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 0.5rem;
			overflow: hidden;
			mask-image: -webkit-radial-gradient(white, black);
			-webkit-mask-image: -webkit-radial-gradient(
				white,
				black
			); // Safari border-radius flicker hack
			transition: all 1s ease;
		}
		.post-content > * {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		.post-link {
			.post-carousel-container {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				.carousel-icon {
					display: block;
					background-image: $carouselIcon;
					background-size: 100% 100%;
					background-position: 0 0;
					position: absolute;
					width: 20px;
					height: 20px;
					top: 0.5rem;
					right: 0.5rem;
					transition: all 0.2s ease;
				}
				.carousel-play-button-icon {
					display: block;
					background-image: $playButtonIcon;
					background-size: 100% 100%;
					background-position: 0 0;
					position: absolute;
					height: 135px;
					left: 50%;
					margin-left: -67px;
					margin-top: -67px;
					top: 50%;
					width: 135px;
				}
			}
			.video-container {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				.video-icon {
					display: block;
					background-image: $videoIcon;
					background-size: 100% 100%;
					background-position: 0 0;
					position: absolute;
					width: 20px;
					height: 20px;
					top: 0.5rem;
					right: 0.5rem;
					transition: all 0.2s ease;
				}
				.video-play-button {
					display: block;
					background-image: $playButtonIcon;
					background-size: 100% 100%;
					background-position: 0 0;
					position: absolute;
					height: 135px;
					left: 50%;
					margin-left: -67px;
					margin-top: -67px;
					top: 50%;
					width: 135px;
				}
			}
			img,
			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%; /* default value: image is centered*/
				transition: opacity 0.2s ease;
			}
			&:hover {
				.post-carousel-container {
					.carousel-icon {
						// opacity: 0;
						transform: scale(1.2);
					}
				}
				.video-container {
					.video-icon {
						// opacity: 0;
						transform: scale(1.2);
					}
				}
			}
		}
	}
}

.loading-skeleton {
	display: inline-block;
	position: relative;
	overflow: hidden;
	background-color: transparent;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#e8e8e8, 0) 0,
			rgba(#e8e8e8, 0.2) 20%,
			rgba(#e8e8e8, 0.5) 50%,
			rgba(#e8e8e8, 0)
		);
		animation: shimmer 1.2s infinite;
		content: "";
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}
