// Fonts
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700|Fira+Mono:500,600");
.source-pro {
	font-family: Source Sans Pro, Harmonia Sans W01, system-ui, -apple-system,
		BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans,
		Helvetica Neue, Fira Sans, sans-serif;
	letter-spacing: 0.02rem;
}
.fira-mono {
	font-family: Fira Mono, Menlo-Regular, Menlo, monospace;
	letter-spacing: 0.025rem;
}

.smooth-scroll {
	scroll-behavior: smooth;
}

.bg-bedroom {
	background-image: $bgBedroom;
	background-size: cover;
	transition: all 0.2s ease;
	@include md {
		background-position: center !important;
	}
}
.bg-fonze {
	background-image: $bgFonze;
	background-size: cover;
	transition: all 0.2s ease;
	opacity: 0;
	@include md {
		background-position: center !important;
		opacity: 0.6;
	}
}

a,
button,
div,
span {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.flex-shrink-0 {
	flex-shrink: 0;
}
.strikeThrough {
	display: inline;
	text-decoration: line-through;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	font-weight: inherit;
	color: inherit;
	opacity: 0.75;
}
.text-shadow {
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.opaque {
	opacity: 0.75;
}
.reverse {
	transform: scaleX(-1);
}
.down {
	transform: rotate(90deg);
}
.will-change {
	will-change: transform;
}
.transition {
	transition: all 0.2s ease;
}
.max-w-screen-xl {
	max-width: 1280px;
}
.p-2px {
	padding: 2px;
}
.pb-full {
	padding-bottom: 100%;
}
.underline-indigo {
	text-decoration: none;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(70%, transparent),
		color-stop(70%, rgba(101, 125, 225, 0.4))
	);
	background-image: linear-gradient(
		180deg,
		transparent 70%,
		rgba(101, 125, 225, 0.4) 0
	);
}
.underline-white {
	text-decoration: none;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(70%, transparent),
		color-stop(70%, rgba(255, 255, 255, 0.2))
	);
	background-image: linear-gradient(
		180deg,
		transparent 70%,
		rgba(255, 255, 255, 0.2) 0
	);
}
.flex-IE {
	flex: 1 0 auto;
}

.translate {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
	.bg-about,
	.bg-video {
		position: relative;
	}
}

.toggled-8 {
	transform: translateX(0.75rem);
}
.toggled-10 {
	transform: translateX(1rem);
}

.bg-image,
.bg-video {
	position: relative;
	width: 100%;
	width: 100vw;
	height: 100%;
	height: 100vh;
}
.bg-image .image {
	position: absolute;
	background-size: cover;
	background-position: 0 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.bg-video .video {
	position: absolute;
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
}
@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

// Navigation
.navigation-bar {
	will-change: scroll-position, transform;
	transition: transform 0.2s ease;
	&.not-scrolled {
		background-color: transparent;
	}
	&.scrolled {
		background-color: rgba(76, 98, 225, 0.96);
	}
	&.unpin {
		transform: translateY(-64px);
	}
	&.pin {
		transform: translateY(0);
	}
	.navigation-link {
		-webkit-tap-highlight-color: transparent;
		&:last-of-type {
			padding-right: 0;
		}
	}
}

.name-logo {
	&.animate {
		&:after {
			content: "_";
			font-weight: 700;
			opacity: 0;
			margin-left: 0.5rem;
			animation: nav-cursor 1.2s infinite;
		}
		@keyframes nav-cursor {
			0% {
				opacity: 0;
			}
			40% {
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			90% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}
}

// Mobile navigation
html.mobile-navigation-visible {
	overflow: hidden;
}

.mobile-navigation-menu {
	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	opacity: 0;
	visibility: hidden;
	.mobile-navigation-link {
		opacity: 0;
	}
	&.show {
		opacity: 1;
		visibility: visible;
		.mobile-navigation-link {
			opacity: 1;
			transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
				-webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
			transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
				opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
			transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
				opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
				-webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
		}
		.mobile-navigation-link:nth-child(4) {
			transition-delay: 0.35s;
		}
		.mobile-navigation-link:nth-child(3) {
			transition-delay: 0.25s;
		}
		.mobile-navigation-link:nth-child(2) {
			transition-delay: 0.2s;
		}
		.mobile-navigation-link:nth-child(1) {
			transition-delay: 0.15s;
		}
	}
}

// Hero
.hero-name svg,
.hero-name img {
	max-width: 100%;
	height: 34px;
}
.hero-title,
.hero-title-2 {
	&.done {
		&:after {
			content: "|";
			font-weight: 600;
			//color: $white;
			opacity: 0;
		}
	}
	&.typing {
		&:after {
			content: "|";
			font-weight: 600;
			//color: $white;
			opacity: 1;
		}
	}
	&.animate {
		&:after {
			content: "|";
			font-weight: 600;
			//color: inherit;
			opacity: 0;
			animation: cursor 1.2s infinite;
		}
		@keyframes cursor {
			0% {
				opacity: 0;
			}
			40% {
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			90% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}
}
mark {
	background: none;
	background-color: transparent;
	color: inherit;
}

// Social buttons
.social-buttons {
	svg {
		//fill: $white;
		padding: 2px;
		transition: transform 0.2s ease;
		&:hover,
		&:focus {
			transform: scale(1.1);
		}
	}
	&.animate {
		will-change: transform, opacity;
		opacity: 0;
		-webkit-animation: slideIn 1s ease-out forwards;
		animation: slideIn 1s ease-out forwards;
		@include md {
			-webkit-animation: slideUp 1s ease-out forwards;
			animation: slideUp 1s ease-out forwards;
			opacity: 1;
		}
	}
	@keyframes slideIn {
		from {
			transform: translate3d(0.36rem, 0, 0);
			opacity: 0;
		}
		to {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
	@keyframes slideUp {
		from {
			transform: translate3d(0, 0.36rem, 0);
			opacity: 0;
		}
		to {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
}

// Horizontal rule animation
.line-seperator {
	height: 2px;
	background-color: transparent;
	-webkit-animation: fwd 1s ease forwards;
	animation: fwd 1s ease forwards;
	will-change: transform;
}
@keyframes fwd {
	from {
		width: 0px;
	}
	to {
		width: 40px;
	}
}
.paragraph-link {
	svg {
		fill: currentColor;
		margin-left: 0.5rem;
	}
}

// Scroll down
.scroll-down {
	opacity: 0;
	&.animate {
		opacity: 1;
		animation: bounce 2.2s infinite;
	}
}
@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

// Slants
.slant-top,
.slant-top-bottom,
.slant-top-bottom-reverse,
.slant-top-reverse {
	position: relative;
}
.slant-top-bottom:after,
.slant-top-bottom-reverse:after,
.slant-top:before,
.slant-top-reverse:before {
	background: inherit;
	content: "";
	display: block;
	height: 50%;
	left: 0;
	position: absolute;
	right: 0;
	z-index: -1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.slant-top:before {
	top: 0;
	-webkit-transform: skewY(2deg);
	transform: skewY(2deg);
	-webkit-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}
.slant-top-reverse:before {
	top: 0;
	-webkit-transform: skewY(-2deg);
	-ms-transform: skewY(-2deg);
	transform: skewY(-2deg);
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	background: inherit;
	content: "";
	display: block;
	height: 50%;
	left: 0;
	position: absolute;
	right: 0;
	z-index: -1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.slant-top-reverse:before {
	top: 0;
	-webkit-transform: skewY(-2deg);
	transform: skewY(-2deg);
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}
.slant-top-bottom:after {
	bottom: 0;
	-webkit-transform: skewY(-2deg);
	transform: skewY(-2deg);
	-webkit-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}
.slant-top-bottom-reverse:after {
	bottom: -4rem;
	-webkit-transform: skewY(2deg);
	-ms-transform: skewY(2deg);
	transform: skewY(2deg);
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}

// Video background
.full-screen {
	position: absolute;
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

// Embedded video
.fw-video {
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	.embed-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// Blog post
.BlogPost {
	p {
		margin-bottom: 2rem;
	}
}

// Projects
.aspect-16x9 {
	padding-bottom: 56.25%;
}
.aspect-1x1 {
	@include aspect-ratio(1, 1);
}
.card {
	transition: all 0.4s;
	transform: translate3d(0, 0, 0);
	&:hover {
		transform: translateY(-0.25rem);
		h2,
		h3 {
			color: hsla(231, 71%, 59%, 1);
		}
	}
}

// Capabilities
.grid {
	display: -ms-grid;
	display: grid;
	grid-gap: 1rem;
	-ms-grid-columns: 3fr 3fr 3fr 3fr 3fr 3fr;
	grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr;
	grid-auto-flow: row;
	grid-auto-rows: 1fr;
	img {
		height: auto;
		padding: 0 35%;
		-webkit-filter: saturate(60%);
		filter: saturate(60%);
		-webkit-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
}

.performance {
	&:hover {
		transform: translateZ(0);
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
}

.dark-mode {
	background-color: #353a41 !important;
	.bg-dark-mode-screen {
		transform: translateZ(0); /* for older browsers */
		will-change: transform;
		background-color: rgba(#10171f, 0.5);
	}
	.invert {
		filter: invert(100%);
	}
	.text-white {
		color: rgba($white, 0.75) !important;
	}
	.opaque {
		opacity: 0.9;
	}
	.Error,
	.Home,
	.ProjectPost,
	.About,
	.InstagramPost,
	.BlogPosts,
	.BlogPost {
		.bg-intro-blue {
			background-color: #314181;
		}
		.bg-image .bg-intro-blue {
			background-color: hsla(231, 71%, 59%, 1);
		}
		.text-intro-blue {
			color: hsla(231, 71%, 59%, 1);
		}
		.bg-white {
			background-color: #10171f;
		}
		.toggle-circle.bg-white {
			background-color: rgba($white, 0.75);
		}
		.toggle-line.bg-black {
			background-color: #353a41;
		}
		.toggle-circle.border-black {
			border-color: #353a41;
		}
		.bg-grey {
			background-color: #353a41;
		}
		.bg-grey-lighter {
			background-color: #09111a;
		}
		.border-grey-lighter {
			border-color: #10171f;
		}
		.bg-grey-lightest {
			background-color: #353a41;
		}
		section.bg-grey-lightest {
			background-color: #09111a;
		}
		.text-black {
			color: rgba($white, 0.75);
		}
		.text-grey-dark {
			color: #b8c2cc;
		}
		.underline-indigo {
			text-decoration: none;
			background-image: -webkit-gradient(
				linear,
				left top,
				left bottom,
				color-stop(70%, transparent),
				color-stop(70%, rgba(255, 255, 255, 0.2))
			);
			background-image: -webkit-linear-gradient(
				top,
				transparent 70%,
				rgba(255, 255, 255, 0.2) 0
			);
			background-image: linear-gradient(
				180deg,
				transparent 70%,
				rgba(255, 255, 255, 0.2) 0
			);
		}
		.underline-white {
			text-decoration: none;
			background-image: -webkit-gradient(
				linear,
				left top,
				left bottom,
				color-stop(70%, transparent),
				color-stop(70%, rgba(255, 255, 255, 0.2))
			);
			background-image: -webkit-linear-gradient(
				top,
				transparent 70%,
				rgba(255, 255, 255, 0.2) 0
			);
			background-image: linear-gradient(
				180deg,
				transparent 70%,
				rgba(255, 255, 255, 0.2) 0
			);
		}
		header {
			.line-seperator {
				background-color: #dae1e7;
			}
		}
		nav {
			&.bg-intro-blue,
			&.bg-grey-lighter {
				color: hsla(231, 71%, 59%, 1);
			}
			&.border-grey-light {
				border-color: #3d4852;
			}
		}
		#work {
			.card {
				.bg-white {
					background-color: #09111a;
				}
				&:hover {
					h2,
					h3 {
						color: hsla(231, 71%, 59%, 1);
					}
				}
			}
		}
		#projects {
			.card {
				.bg-white {
					background-color: #10171f;
				}
				&:hover {
					h2,
					h3 {
						color: hsla(231, 71%, 59%, 1);
					}
				}
			}
		}
	}
	.Error,
	.Home,
	.About {
		.toggle-line.bg-black {
			background-color: #10171f;
		}
		.toggle-circle.border-black {
			border-color: #10171f;
		}
	}
	.ProjectPost,
	.BlogPosts,
	.BlogPost,
	.InstagramPost {
		nav {
			&.bg-grey-lighter {
				background-color: #10171f;
			}
		}
	}
	.BlogPosts {
		.card {
			.bg-white {
				background-color: #10171f;
			}
			&:hover {
				h2,
				h3 {
					color: hsla(231, 71%, 59%, 1);
				}
			}
		}
	}
	.About {
		.bg-grey-lightest {
			background-color: #353a41;
		}
	}
	.InstagramPost {
		.sidebar.bg-grey-lightest {
			background-color: #10171f;
			@include md {
				background-color: #09111a;
			}
		}
		.bg-black {
			background-color: #09111a;
		}
		.media-container.bg-black {
			background-color: #0b1116;
		}
		.slide-counter-display.bg-black {
			background-color: #10171f;
		}
		button.next-button:hover svg,
		button.prev-button:hover svg {
			background-color: #10171f;
		}
		.bg-grey-lightest {
			background-color: #353a41;
		}
		.border-grey-lighter,
		.border-black {
			border-color: #353a41; // #09111a
			@include md {
				border-color: #10171f;
			}
		}
		.loading-skeleton.bg-grey,
		.error-skeleton.bg-grey {
			background-color: #10171f;
		}
		.loading-skeleton.bg-grey-dark,
		.error-skeleton.bg-grey-dark {
			background-color: #10171f;
		}
		.text-grey-dark {
			color: #555;
		}
		.loading-skeleton.bg-indigo-lighter,
		.error-skeleton.bg-indigo-lighter {
			background-color: rgba(255, 255, 255, 0.2);
		}
		.loading-skeleton,
		.error-skeleton {
			&::after {
				background-image: linear-gradient(
					90deg,
					rgba(#535a64, 0) 0,
					rgba(#535a64, 0.2) 20%,
					rgba(#535a64, 0.5) 50%,
					rgba(#535a64, 0)
				);
				animation: shimmer 1.2s infinite;
			}
		}
	}
	.bg-modal {
		background-color: hsla(213, 16%, 11%, 0.8);
	}
	.instagram-feed {
		.loading-skeleton.bg-grey,
		.error-skeleton.bg-grey {
			background-color: #353a41;
		}
		.loading-skeleton.bg-grey-dark,
		.error-skeleton.bg-grey-dark {
			background-color: #353a41;
		}
		.text-grey-dark {
			color: #555;
		}
		.loading-skeleton.bg-indigo-lighter,
		.error-skeleton.bg-indigo-lighter {
			background-color: rgba(255, 255, 255, 0.2);
		}
		.loading-skeleton,
		.error-skeleton {
			&::after {
				background-image: linear-gradient(
					90deg,
					rgba(#535a64, 0) 0,
					rgba(#535a64, 0.2) 20%,
					rgba(#535a64, 0.5) 50%,
					rgba(#535a64, 0)
				);
				animation: shimmer 1.2s infinite;
			}
		}
	}
	.modal {
		.sidebar.bg-white {
			background-color: #09111a; // #09111a
		}
		.bg-black {
			background-color: #353a41;
		}
		.media-container.bg-black {
			background-color: #10171f;
		}
		.slide-counter-display.bg-black {
			background-color: #10171f;
		}
		button.next-button:hover svg,
		button.prev-button:hover svg {
			background-color: #10171f;
		}
		.border-grey-lightest,
		.border-black {
			border-color: #10171f;
		}
		.loading-skeleton.bg-grey,
		.error-skeleton.bg-grey {
			background-color: #353a41;
		}
		.loading-skeleton.bg-grey-dark,
		.error-skeleton.bg-grey-dark {
			background-color: #353a41;
		}
		.text-grey-dark {
			color: #555;
		}
		.loading-skeleton.bg-indigo-lighter,
		.error-skeleton.bg-indigo-lighter {
			background-color: rgba(255, 255, 255, 0.2);
		}
		.loading-skeleton,
		.error-skeleton {
			&::after {
				background-image: linear-gradient(
					90deg,
					rgba(#535a64, 0) 0,
					rgba(#535a64, 0.2) 20%,
					rgba(#535a64, 0.5) 50%,
					rgba(#535a64, 0)
				);
				animation: shimmer 1.2s infinite;
			}
		}
	}
	.button-container,
	.buttons {
		.text-white {
			color: #f8fafc;
		}
	}
	.slide-counter-display.text-white {
		color: #f8fafc;
	}
}
